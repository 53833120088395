import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './ListCategories.module.scss';
import { ReactComponent as ArrowRight } from '../../images/icons/arrowRight.svg';
import { getPagePath } from '../../utils/appRoutes';
import { ARD_CATEGORY_CONTENT, ARD_CATEGORY_SEARCH } from '../../utils/appRoutes.definitions';
import { FormattedMessage } from 'react-intl';

const CategoryScopeSection = ({ categoryPath, categoryName, categoryLevel, categoriesList, scrollToTop }) => {
  return (
    <div className={styles.links}>
      {categoriesList?.map(([path, name], index) => (
        <>
          <Link
            key={`${categoryLevel}item${index}`}
            className={styles.link}
            to={getPagePath(ARD_CATEGORY_CONTENT, path)}
            onClick={scrollToTop}
          >
            <span>{name}</span>
            <ArrowRight />
          </Link>
        </>
      ))}
      <Link
        className={classnames(styles.link, styles.allLink)}
        to={getPagePath(ARD_CATEGORY_SEARCH, categoryPath)}
        onClick={scrollToTop}
      >
        <span>
          <FormattedMessage id="categories.allProducts" />
          &nbsp;{categoryName?.toLowerCase()}
        </span>
        <ArrowRight />
      </Link>
    </div>
  );
};

const ListCategories = ({ categoryPath, categories, categoryNamesFromPath = [] }) => {
  const [numColumns, setNumColumns] = useState(3);
  //todo no sense in this function here. Move it to the app component
  const scrollToTop = useCallback(() => {
    window.scrollTo({ behavior: 'smooth', left: 0, top: 0 });
  }, []);

  if (!categories) return null;

  const categoriesList = categories.category || [];
  const subCategories = categories.subcategory;
  const subSubCategories = categories.subsubcategory;

  const [categoryName, subCategoryName, subSubCategoryName] = categoryNamesFromPath;

  const showCategories = !subSubCategories && !subCategories;
  const showSubCategories = !subSubCategories;
  const showSubSubCategories = !!subSubCategories && !subSubCategoryName;

  const currentLevel =
    (showSubSubCategories && 'subSubCategories') ||
    (showSubCategories && 'subCategories') ||
    (showCategories && 'categories') ||
    'subSubCategory';

  const categoryLevelParams = {
    subSubCategory: {
      list: [],
      name: subSubCategoryName,
      level: 'subSubCategory',
    },
    subSubCategories: {
      list: subSubCategories,
      name: subCategoryName,
      level: 'subSubCategories',
    },
    subCategories: {
      list: subCategories,
      name: categoryName,
      level: 'subCategories',
    },
    categories: {
      list: categoriesList,
      name: categoryName,
      level: 'categories',
    },
  };

  const currentLevelParams = currentLevel && categoryLevelParams[currentLevel];

  useEffect(() => {
    function calculateNumColumns() {
      const screenWidth = window?.innerWidth;
      if (screenWidth >= 1200) {
        return 3;
      } else if (screenWidth >= 768) {
        return 2;
      } else {
        return 1;
      }
    }
    const initialColumns = calculateNumColumns();
    setNumColumns(initialColumns);
    function handleResize() {
      setNumColumns(calculateNumColumns());
    }
    window?.addEventListener('resize', handleResize);
    return () => window?.removeEventListener('resize', handleResize);
  }, [categories]);

  let sortedCategory = currentLevelParams?.list || [];
  sortedCategory.sort((a, b) => a[1].localeCompare(b[1]));
  const sortedDataArray = [];
  const columnSize = Math.ceil(sortedCategory.length / numColumns);
  const columnMinSize = Math.floor(sortedCategory.length / numColumns);
  const numberInColumn = (numColumns - columnSize * numColumns + sortedCategory.length) % numColumns;

  for (let i = 0; i < columnSize; i++) {
    let k = 0;
    for (let j = 0; j < numColumns; j++) {
      if (j === 0) {
        k = i;
      } else {
        if (j <= numberInColumn) {
          k = k + columnSize;
        } else {
          k = k + columnMinSize;
        }
      }
      if (k < sortedCategory.length) {
        sortedDataArray.push(sortedCategory[k]);
      }
    }
  }

  return (
    <div className={styles.root}>
      <div className={classnames('container')}>
        <CategoryScopeSection
          categoryLevel={currentLevelParams?.level}
          categoryName={currentLevelParams?.name}
          categoryPath={categoryPath}
          categoriesList={sortedDataArray}
          scrollToTop={scrollToTop}
        />
      </div>
    </div>
  );
};

ListCategories.propTypes = {
  categories: PropTypes.shape({
    category: PropTypes.arrayOf(PropTypes.string).isRequired,
    subcategory: PropTypes.arrayOf(PropTypes.string).isRequired,
    subsubcategory: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  categoryNamesFromPath: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ListCategories;
